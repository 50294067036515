import React from "react"
import { Layout, Divider } from "antd"
import { useIntl } from "gatsby-plugin-intl"
import { Link } from "gatsby"
import logo from "../images/2019-02-logo principal-digitalway.svg"

const { Footer } = Layout

export default () => {
  const intl = useIntl()

  return (
    <Footer
      style={{
        textAlign: "center",
        backgroundColor: "#f5f5f5",
        marginTop: "-10px",
      }}
    >
      <img src={logo} alt="logo" width="100px" />
      <Divider dashed />
      <div>DW Agency S.A.S - {new Date().getFullYear()}</div>
      <div>
        <Link to="/privacidad">
          {intl.formatMessage({ id: "footerPrivacyPolicy" })}
        </Link>
      </div>
    </Footer>
  )
}
