import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { Helmet } from "react-helmet";

export default ({ title }) => {
  const intl = useIntl();

  return (
    <Helmet>
      <meta charSet='utf-8' />
      <title>{title}</title>
      <meta name='title' content={title} />
      <meta
        name='description'
        content={intl.formatMessage({ id: "seoDescription" })}
      />
      <meta property='og:type' content='website' />
      <meta
        property='og:url'
        content={intl.formatMessage({ id: "digitalWayUrl" })}
      />
      <meta
        property='og:title'
        content={intl.formatMessage({ id: "seoSocialTitle" })}
      />
      <meta
        property='og:description'
        content={intl.formatMessage({ id: "seoSocialDescription" })}
      />
      <meta
        property='og:image'
        content='https://digitalway.guru/social-digitalway.png'
      />
      <meta property='twitter:card' content='summary_large_image' />
      <meta
        property='twitter:url'
        content={intl.formatMessage({ id: "digitalWayUrl" })}
      />
      <meta
        property='twitter:title'
        content={intl.formatMessage({ id: "seoSocialTitle" })}
      />
      <meta
        property='twitter:description'
        content={intl.formatMessage({ id: "seoSocialDescription" })}
      />
      <meta
        property='twitter:image'
        content='https://digitalway.guru/social-digitalway.png'
      />
      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='/apple-touch-icon.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href='/favicon-32x32.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='16x16'
        href='/favicon-16x16.png'
      />
      <link rel='manifest' href='/site.webmanifest' />
      <meta name='msapplication-TileColor' content='#da532c' />
      <meta name='theme-color' content='#ffffff'></meta>{" "}
      <link
        rel='canonical'
        href={intl.formatMessage({ id: "digitalWayUrl" })}
      />
    </Helmet>
  );
};
